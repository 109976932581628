/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto:400,700');

html {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
}

.colored-spinner circle {
  stroke: #0062af !important;
}

.body-class {
  margin: 0px;
  max-height: 740px;
  font-family: 'Roboto Condensed', sans-serif;
}

.main-content {
  width: 1390px;
  height: 740px;
  margin: auto;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
}

#body-id .mat-menu-panel {
  display: inline-block;
  min-width: inherit;
  max-width: 36px;
  min-width: 36px;
}

#body-id button .mat-menu-item .mat-icon {
  line-height: 0px;
  height: 36px;
  color: #000000;
  opacity: 1;
}

#body-id div .mat-menu-ripple {
  line-height: 0px;
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  min-width: 36px;
  max-width: 36px;
  margin: 4px;
}

#body-id .editor-form-icon {
  margin: 6px;
  text-align: center;
  color: black;
  opacity: 1;
}

#body-id .font-bold {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}

#body-id .font-14px {
  font-size: 14px;
}

#body-id .font-18px {
  font-size: 18px;
}

#body-id .font-16px {
  font-size: 16px;
}

#body-id .font-10px {
  font-size: 10px;
}

#body-id .font-24px {
  font-size: 24px;
}

.maincontent {
  background-color: white;
  display: block;
  max-width: 100%;
}

#body-id .controlbutton {
  background-color: #e3131f;
  color: #ffffff;
}

#body-id .margin-left-26 {
  margin-left: 26px;
}

#body-id .controlbutton:disabled {
  background-color: #b0b0b0;
  color: #ffffff;
}

#body-id .font-red {
  color: #e3131f;
}

#body-id .font-blue {
  color: #0062af;
}

/*
    ALEGRASC
*/

@font-face {
  font-family: 'AlegreyaSC-Bold';
  src: url('/assets/fonts/Alegreya_SC/AlegreyaSC-Bold.ttf');
}

@font-face {
  font-family: 'AlegreyaSC-BoldItalic';
  src: url('assets/fonts/Alegreya_SC/AlegreyaSC-BoldItalic.ttf');
}

@font-face {
  font-family: 'AlegreyaSC-Italic';
  src: url('assets/fonts/Alegreya_SC/AlegreyaSC-Italic.ttf');
}

@font-face {
  font-family: 'AlegreyaSC-Regular';
  src: url('assets/fonts/Alegreya_SC/AlegreyaSC-Regular.ttf');
}

/*
    Amatic_SC
*/

@font-face {
  font-family: 'AmaticSC-Bold';
  src: url('assets/fonts/Amatic_SC/AmaticSC-Bold.ttf');
}

@font-face {
  font-family: 'AmaticSC-Regular';
  src: url('assets/fonts/Amatic_SC/AmaticSC-Regular.ttf');
}

/*
    Athiti
*/

@font-face {
  font-family: 'Athiti-Bold';
  src: url('/assets/fonts/Athiti/Athiti-Bold.ttf');
}

@font-face {
  font-family: 'Athiti-Regular';
  src: url('assets/fonts/Athiti/Athiti-Regular.ttf');
}

/*
Bungee
*/

@font-face {
  font-family: 'Bungee-Regular';
  src: url('assets/fonts/Bungee/Bungee-Regular.ttf');
}

/*
Economica
*/

@font-face {
  font-family: 'Economica-Bold';
  src: url('/assets/fonts/Economica/Economica-Bold.ttf');
}

@font-face {
  font-family: 'Economica-BoldItalic';
  src: url('assets/fonts/Economica/Economica-BoldItalic.ttf');
}

@font-face {
  font-family: 'Economica-Italic';
  src: url('assets/fonts/Economica/Economica-Italic.ttf');
}

@font-face {
  font-family: 'Economica-Regular';
  src: url('assets/fonts/Economica/Economica-Regular.ttf');
}

/*
JosefinSans
*/

@font-face {
  font-family: 'JosefinSans-Bold';
  src: url('/assets/fonts/Josefin_Sans/JosefinSans-Bold.ttf');
}

@font-face {
  font-family: 'JosefinSans-BoldItalic';
  src: url('assets/fonts/Josefin_Sans/JosefinSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'JosefinSans-Italic';
  src: url('assets/fonts/Josefin_Sans/JosefinSans-Italic.ttf');
}

@font-face {
  font-family: 'JosefinSans-Regular';
  src: url('assets/fonts/Josefin_Sans/JosefinSans-Regular.ttf');
}

/*
    Kalam
*/

@font-face {
  font-family: 'Kalam-Bold';
  src: url('/assets/fonts/Kalam/Kalam-Bold.ttf');
}

@font-face {
  font-family: 'Kalam-Regular';
  src: url('assets/fonts/Kalam/Kalam-Regular.ttf');
}

/*
KaushanSScript
*/

@font-face {
  font-family: 'KaushanScript-Regular';
  src: url('assets/fonts/Kaushan_Script/KaushanScript-Regular.ttf');
}

/*
Khand
*/

@font-face {
  font-family: 'Khand-Bold';
  src: url('/assets/fonts/Khand/Khand-Bold.ttf');
}

@font-face {
  font-family: 'Khand-Regular';
  src: url('assets/fonts/Khand/Khand-Regular.ttf');
}

/*
MarcellusSC
*/

@font-face {
  font-family: 'MarcellusSC-Regular';
  src: url('assets/fonts/Marcellus_SC/MarcellusSC-Regular.ttf');
}

/*
Mate
*/

@font-face {
  font-family: 'Mate-Regular';
  src: url('assets/fonts/Mate/Mate-Regular.ttf');
}

@font-face {
  font-family: 'Mate-Italic';
  src: url('assets/fonts/Mate/Mate-Italic.ttf');
}

/*
    Mate_SC
*/

@font-face {
  font-family: 'MateSC-Regular';
  src: url('assets/fonts/Mate_SC/MateSC-Regular.ttf');
}

/*
    OpenSans
*/

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('assets/fonts/Open_Sans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'OpenSans-BoldItalic';
  src: url('assets/fonts/Open_Sans/OpenSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'OpenSans-Italic';
  src: url('assets/fonts/Open_Sans/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('assets/fonts/Open_Sans/OpenSans-Regular.ttf');
}

/*
    Poppins
*/

@font-face {
  font-family: 'Poppins-Bold';
  src: url('assets/fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf');
}

/*
    Quicksand
*/

@font-face {
  font-family: 'Quicksand-Bold';
  src: url('assets/fonts/Quicksand/Quicksand-Bold.ttf');
}

@font-face {
  font-family: 'Quicksand-Regular';
  src: url('assets/fonts/Quicksand/Quicksand-Regular.ttf');
}

/*
    Sacramento
*/

@font-face {
  font-family: 'Sacramento-Regular';
  src: url('assets/fonts/Sacramento/Sacramento-Regular.ttf');
}

/*
    SigmarOne
*/

@font-face {
  font-family: 'SigmarOne-Regular';
  src: url('assets/fonts/Sigmar_One/SigmarOne-Regular.ttf');
}

.btn {
  font-family: 'Roboto Condensed', sans-serif;
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.btn.disabled,
.btn:disabled {
  cursor: default;
  opacity: 0.65;
}

.btn-secondary {
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc;
}

.btn-secondary.focus,
.btn-secondary:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
  box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #fff;
  border-color: #ccc;
}

.btn-primary {
  color: #fff;
  background-color: #0669b2;
  border: none;
}

.btn-primary.focus,
.btn-primary:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5);
  box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #c5d1df;
  border-color: #c5d1df;
  color: #8590a2;
}

.btn-outline-primary {
  color: #0275d8;
  background-image: none;
  background-color: transparent;
  border-color: #0275d8;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5);
  box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #9ea2a9;
  border-color: #9ea2a9;
  background-color: transparent;
}

[role='button'],
a,
area,
button,
input,
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

a,
button {
  color: #0068b3;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.25);
  box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.25);
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: button;
}

.sortimo_text_lightgray {
  background: #dee7f0;
  color: #546373;
}

.sortimo_text_default {
  color: #546373;
}

.form-control {
  /*height: 50px;*/
  line-height: 50px;
  box-shadow: none;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  color: #3c3c3c;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #7c8c9c;
  border-radius: 0;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-group .has-error input {
  background: #fec3c3;
  border-color: #fd7b7b;
}

.form-group input.has-error {
  background: #fec3c3;
  border-color: #fd7b7b;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.svg_arrow {
  height: 11px;
  width: 12px;
}

.form-group {
  line-height: 50px;
}

.form-group div .has-error {
  padding-bottom: 10px;
}

.form-group div .has-error textarea+.help-block {
  margin-bottom: 0;
  text-align: initial;
  font-size: initial;
}

.form-group div .has-error .help-block {
  padding-bottom: 0;
}

.form-group div .has-error textarea.form-control {
  background: #fec3c3;
  border-color: #fd7b7b;
}

.form-group input {
  display: inline-block;
  width: 100%;
}

.form-group input[type='checkbox'] {
  width: auto;
}

.form-group .checkbox label.control-label {
  width: 100%;
  padding: 0;
}

.form-group .checkbox label.control-label input {
  padding: 0;
  margin: 0;
  display: block;
  float: left;
  width: 30px;
  position: relative;
}

.form-group .checkbox label.control-label span {
  width: auto;
  display: block;
  float: left;
  line-height: normal;
}

.form_radiogroup div {
  display: inline-block;
  width: 100px;
}

.form_radiogroup div input {
  width: auto;
}

.sortimo_form .row {
  position: relative;
  bottom: 0;
  padding-right: 0;
  padding-bottom: 10px;
}

.sortimo_btn_cta_dark_b {
  background: #0669b2;
}

.sortimo_button {
  padding: 14px 20px 14px 20px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px !important;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 17px;
  width: auto;
  display: inline-block;
}

.sortimo_button .left {
  margin-right: 17px;
  fill: #0068b3;
  width: '11px';
  height: '12px';
  transform: scale(-1, 1);
}

.sortimo_button .right {
  margin-right: 0px;
  fill: #fff;
  width: '11px';
  height: '12px';
}

.sortimo_button .mat-icon {
  width: 11px;
  height: 15px;
}

.sortimo_form label {
  width: 100%;
  font-weight: bold;
  color: #546373;
  font-size: 11pt;
  display: inline-block;
  vertical-align: middle;
  padding-top: 20px;
  line-height: 20px;
}

.sortimo_headerbar {
  background: #c5d1df;
  font-size: 20px;
  line-height: 20px;
  padding: 15px 30px;
}

.headline_03_dk {
  font-size: 17px !important;
  color: #546373 !important;
}

.cls-1 {
  fill: #526372;
  fill-rule: evenodd;
}

.sortimo_form .row .has-error .help-block {
  line-height: 20px;
  padding-top: 10px;
  font-weight: bold;
  font-family: 'Roboto Condensed', sans-serif;
}

.margin-bottom-xl {
  margin-bottom: 25px;
}

.margin-right-xl {
  margin-right: 25px;
}

.margin-left-xl {
  margin-left: 25px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-top-xl {
  margin-top: 25px;
}

.has-error .help-block,
.help-block.has-error {
  font-weight: 600;
  color: #ed1c24;
}

.help-block.has-error {
  padding-top: 0px;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #3c3c3c;
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}

.alert {
  padding-left: 30px;
  padding-right: 30px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 0;
}

.background-white {
  background-color: white;
}

/* mat dialog style*/

.custom-finish-order-dialog-container .mat-dialog-container {
  /* add your styles */
  padding: 0px;
  max-width: 500px;
}

.upload-workflow-container .mat-dialog-container {
  /* add your styles */
  padding: 0px;
  overflow: unset;
  border-radius: 0px;
  border: 1px solid #219bd7;
}

.mat-select-arrow-wrapper {
  padding: 0 20px 0 11px;
  margin: 0%;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 29px;
  box-shadow: none;
  background: url(assets/icons/arrow_select-27x27.png) no-repeat right center;
}

.mat-select-value {
  height: 29px;
  vertical-align: middle;
}

input[type='number'] {
  padding: 0 10px 0 11px;
  margin: auto 10px auto 5px !important;
  height: 29px !important;
  width: 55px;
  box-shadow: none;
  border: 1px solid #7c8c9c;
  color: #3c3c3c;
  border-radius: 0;
  position: relative;
}

.mat-select-arrow {
  display: none;
  visibility: hidden;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  width: 28px;
  height: 100%;
  border-left: 1px solid #bbb;
  opacity: 0.5;
  /* shows Spin Buttons per default (Chrome >= 39) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

input[type='number']::-webkit-inner-spin-button {
  background: url('../src/assets/icons/V2/arrow_select-top-down.png') no-repeat center center;
}

input[type='number']::-webkit-inner-spin-button:hover,
input[type='number']::-webkit-inner-spin-button:active {
  opacity: 0.8;
}

image-cropper div .overlay {
  outline: none !important;
}

image-cropper .cropper .topright .square {
  border: 0px solid #0275d8 !important;
  border-right-width: 5px !important;
  border-top-width: 5px !important;
  width: 12px !important;
  height: 12px !important;
  background: transparent !important;
}

image-cropper .cropper .topleft .square {
  border: 0px solid #0275d8 !important;
  border-left-width: 5px !important;
  border-top-width: 5px !important;
  width: 12px !important;
  height: 12px !important;
  background: transparent !important;
}

image-cropper .cropper .bottomleft .square {
  border: 0px solid #0275d8 !important;
  border-left-width: 5px !important;
  border-bottom-width: 5px !important;
  width: 12px !important;
  height: 12px !important;
  background: transparent !important;
}

image-cropper .cropper .bottomright .square {
  border: 0px solid #0275d8 !important;
  border-right-width: 5px !important;
  border-bottom-width: 5px !important;
  width: 12px !important;
  height: 12px !important;
  background: transparent !important;
}

image-cropper .cropper .top .square {
  border: 0px solid #0275d8 !important;
  border-top-width: 5px !important;
  width: 12px !important;
  height: 12px !important;
  background: transparent !important;
}

image-cropper .cropper .bottom .square {
  border: 0px solid #0275d8 !important;
  border-bottom-width: 5px !important;
  width: 12px !important;
  height: 12px !important;
  background: transparent !important;
}

image-cropper .cropper .right .square {
  border: 0px solid #0275d8 !important;
  border-right-width: 5px !important;
  width: 12px !important;
  height: 12px !important;
  background: transparent !important;
}

image-cropper .cropper .left .square {
  border: 0px solid #0275d8 !important;
  border-left-width: 5px !important;
  width: 12px !important;
  height: 12px !important;
  background: transparent !important;
}

@mixin flex-container {
  display: flex;
}

/* Flex row/col + grid. */
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
}

/* Flex-wrap utils. */
.flex-wrap {
  @include flex-container();
  flex-wrap: wrap;
}

.flex-col-xs {
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}

/* Gap. */
.gap-4 {
  @include flex-container();
  gap: 4px;
}

.gap-5 {
  @include flex-container();
  gap: 5px;
}

.gap-8 {
  @include flex-container();
  gap: 8px;
}

.gap-10 {
  @include flex-container();
  gap: 10px;
}

.gap-12 {
  @include flex-container();
  gap: 12px;
}

.gap-16 {
  @include flex-container();
  gap: 16px;
}

/* // Justify content. */
.space-between {
  @include flex-container();
  justify-content: space-between;
}

.justify-center {
  @include flex-container();
  justify-content: center;
}

.justify-flex-start {
  @include flex-container();
  justify-content: flex-start;
}

.justify-flex-end {
  @include flex-container();
  justify-content: flex-end;
}

.justify-start {
  @include flex-container();
  justify-content: flex-start;
}

/* // Align items */
.items-center {
  @include flex-container();
  align-items: center;
}

.items-end {
  @include flex-container();
  align-items: flex-end;
}

/* Align Self */
.self-stretch {
  @include flex-container();
  align-self: stretch;
}

/* // Flex/grow/shrink properties https://developer.mozilla.org/en-US/docs/Web/CSS/flex. */
.flex-1 {
  @include flex-container();
  flex: 1
}

.flex-50 {
  @include flex-container();
  flex: 1 1 100%;
}

.flex-25 {
  @include flex-container();
  flex: 1 1 100%;
}

.flex-100 {
  @include flex-container();
  flex: 1 1 100%;
}

.flex-grow {
  @include flex-container();
  flex-grow: 1;
}

.flex-basis-20 {
  @include flex-container();
  flex-basis: 20%;
}

.flex-basis-55 {
  @include flex-container();
  flex-basis: 55%;
}

.flex-basis-27 {
  @include flex-container();
  flex-basis: 27%;
}

.flex-basis-5 {
  @include flex-container();
  flex-basis: 5%;
}

.flex-basis-8 {
  @include flex-container();
  flex-basis: 8%;
}

.flex-basis-25 {
  @include flex-container();
  flex-basis: 25%;
}

.flex-basis-10 {
  @include flex-container();
  flex-basis: 10%;
}

.flex-basis-50 {
  @include flex-container();
  flex-basis: 50%;
}
